.show_more_button {
  display: none;
  position: absolute;
  color: var(--color-gray-2);
  right: 0;
  bottom: 0;
  background: var(--color-white);
  padding: 1px 10px 2px;
  font-weight: 500;
  font-size: 11px;
  border: none;
  box-shadow: -15px -2px 10px var(--color-white);
  pointer-events: none;
}

.show_more_button::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: 60px;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, var(--color-white) 100%);
}
/* SAFARI FIX */
@supports (-webkit-hyphens: none) {
  .show_more_button::before {
    background: var(--color-white);
    width: 4px;
  }
}

@media screen and (max-width: 560px) {
  .smb_visible {
    display: block !important;
  }
}
