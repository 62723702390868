.widget {
  padding: 10px 0;
  margin: 0 0 0 -1px;
  width: calc(100% + 2px);
  border-bottom: 1px solid var(--color-gray-3);
  border-top: 1px solid var(--color-black);
  border-right: 4px solid var(--color-white-1);
  border-left: 4px solid var(--color-white-1);
}

.widget_bg_dark_blue {
  background: var(--color-blue-1);
}

.widget_bg_blue {
  background: var(--color-blue-3);
}

.apps_group {
  margin: 0;
  text-align: center;
}

.apps_group__title {
  color: var(--color-white);
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;
  margin: 0 0 10px;
}

.apps_group__title em {
  font: inherit;
  color: inherit;
  font-weight: 600;
}

.apps_group ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.apps__mobile_apps {
  width: auto;
  height: 43px;
  margin: 0 5px;
}

.apps__social_media {
  width: auto;
  height: 32px;
  margin: 0 10px;
}

.apps__social_media,
.apps__mobile_apps {
  transition: transform 0.2s ease-in-out;
}

.apps_group__item a {
  margin: 0px;
  padding: 0px;
}
.apps_group__item a:hover .apps__mobile_apps {
  transform: scale(1.02);
}
.apps_group__item a:hover .apps__social_media {
  transform: scale(1.06);
}
