.lds_ring__container {
  text-align: center;
  padding: 10px;
}

.lds_ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  z-index: 10;
}

.lds_ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 0;
  border: 4px solid var(--color-red-1);
  border-radius: 50%;
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-red-1) transparent transparent transparent;
}
.lds_ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds_ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds_ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.ring_sm {
  width: 13px;
  height: 13px;
}
.ring_sm div {
  width: 17px;
  height: 17px;
  border-width: 3px;
}

@keyframes lds_ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
