.search_trip__container {
  position: relative;
}

.search_trip__title {
  margin: 0 0 10px;
  padding: 0;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.375rem;
  color: var(--color-red-1);
}

.search_trip__options_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search_trip__restore_filters {
  margin: 1rem 0 0;
}

@media screen and (max-width: 560px) {
  .search_trip__title {
    font-size: 1.5rem;
    margin: 0 0 2px;
  }

  .stc_minimized {
    max-height: 123px;
    overflow: hidden;
  }

  .stc_minimized::after {
    content: "";
    display: block;
    position: relative;
    left: 0;
    bottom: 243px;
    height: 50px;
    width: 100%;
    background: linear-gradient(0deg, white, white, transparent, transparent, transparent);
    pointer-events: none;
  }
}