.table_header {
  position: relative;
  top: -1px;
  left: -1px;
  margin-bottom: -1px;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 2px);
  padding: 10px 11px;
  background: var(--color-red-1);
  border-radius: var(--radius-sm) var(--radius-sm) 0 0;
  z-index: 5;
}

.table_header__titles {
  color: var(--color-white);
  margin-right: 0.5rem;
}

.table_header__title {
  margin-bottom: 0.5rem;
}

.table_header__title h1 {
  display: inline;
  font-weight: 700;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.table_header__title button {
  display: inline;
  padding: 0;
  margin-top: -0.5rem;
  background: transparent;
  border: none;
  transform: translateY(0.5rem);
}

.table_header__button_icon {
  display: block;
  width: auto;
  height: 30px;
  cursor: pointer;
}

.table_header__subtitle {
  font-weight: 500;
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

.table_header__button_sort {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  display: none;
}

.thbs_cursor_auto {
  cursor: auto !important;
}

.thbs_disabled {
  fill: var(--color-red-2);
}

.th__sorting_options_container {
  display: none;
  position: absolute;
  bottom: 15px;
  right: 5px;
  width: 200px;
  padding: 0.75rem 7px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-1);
  border-radius: var(--radius-sm);
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  transform: translateY(100%);
  overflow: hidden;
  z-index: 20;
}

.th__sorting_options span {
  display: block;
  font-weight: 600;
  font-size: 1rem;
  line-height: 10px;
  color: var(--color-blue-1);
  margin: 0 0 0.625rem 0;
}

.th__sorting_options li {
  margin-top: 5px;
}

.thsor__open {
  display: block;
}

.table_header__prices_info {
  padding: 2px 0.65rem 1px;
  margin: 0;
  font-size: 0.9rem;
  text-align: center;
  color: var(--color-gray-2);
  background: var(--color-white);
}

.table_header__prices_info_container {
  position: relative;
  border-bottom: 1px solid var(--color-gray-4);
}

@media screen and (max-width: 560px) {
  .th__sorting_options_container {
    bottom: 15px;
    right: 5px;
    width: 200px;
    padding: 1.25rem 1rem;
  }

  .th__sorting_options span {
    font-size: 1.25rem;
    margin: 0 0 1rem 0;
  }

  .th__sorting_options li {
    margin-top: 1.1rem;
  }

  .table_header__prices_info_container {
    cursor: pointer;
  }

  .thpic_minimized {
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
