.apps_icon {
  color: var(--color-black);
  font-size: 1.1rem;
  line-height: 2.5rem;
  text-decoration: none;
}

.apps_icon:hover {
  text-decoration: underline;
  text-decoration-color: var(--color-blue-1);
}

.apps_icon img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  transform: translateY(3px);
}
