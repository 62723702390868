.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  background: var(--color-white);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 60px;
  z-index: 90;
}

.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.header__logo img {
  height: 28px;
}

.header__central_icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  max-width: 280px;
}

.header__central_icons li:first-child {
  margin-left: 17px;
}

.header__v_bar {
  border-left: 1px solid var(--color-gray-4);
  height: 20px;
}

.header__right_icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  height: 100%;
}

.header__search_container {
  position: relative;
  display: inline;
}

.header__input_field_portal {
  position: absolute;
  top: 0;
  left: 0;
  height: 44px;
  width: var(--header-input-width);
  background: transparent;
  transform: translate(-100%, -10px);
  visibility: hidden;
}

.header__icon {
  height: 20px;
  width: auto;
  margin-left: 17px;
  flex-shrink: 0;
  cursor: pointer;
}

.header__apps_icon img {
  width: 20px;
  height: 20px;
}

.header__alert_icon {
  position: relative;
  top: 1px;
}

.header__alerts_number {
  position: absolute;
  top: -4px;
  right: -2px;
  display: none;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  background-color: var(--color-red-3);
  font-weight: 700;
  font-size: 9px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  pointer-events: none;
}

@media screen and (max-width: 740px) {
  .header__central_icons {
    max-width: 240px;
  }
  .header__icon {
    margin-left: 10px;
  }
}

@media screen and (max-width: 560px) {
  .header__icon {
    margin-left: 17px;
  }
  .header__content {
    padding: 0 15px;
  }
  .header__central_icons {
    max-width: 220px;
  }
  .hci_hide_1 {
    display: none;
  }
  .header__input_field_portal {
    flex-grow: 1;
  }
  #share_icon {
    display: none !important;
  }
}

@media screen and (max-width: 520px) {
  .header__central_icons {
    justify-content: flex-end;
  }
  .header__central_icons li:first-child {
    margin-left: 10px;
  }
  .header__apps_icon,
  .header__icon {
    margin-left: 15px;
  }
  .hci_hide_2 {
    display: none;
  }
}

@media screen and (max-width: 490px) {
  .hci_hide_3 {
    display: none;
  }
}

@media screen and (max-width: 440px) {
  .header__logo img {
    height: 26px;
  }
}

@media screen and (max-width: 415px) {
  .header__apps_icon,
  .header__icon {
    margin-left: 10px;
  }
}

@media screen and (max-width: 400px) {
  .header__input_field_portal {
    max-width: calc(100vw - 115px);
  }
}

@media screen and (max-width: 360px) {
  .header__input_field_portal {
    max-width: 245px;
  }
}
