.header_input__close_icon {
  width: auto;
  height: 16px;
  margin-bottom: -4px;
  cursor: pointer;
}

.header_input__close_icon_color {
  stroke: var(--color-gray-6);
}
