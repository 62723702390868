#self-promo {
  position: relative;
  display: none;
  padding: calc(1.25rem - 6px) 2.75rem calc(1.25rem - 6px) 1.25rem;
  background: var(--color-yellow-1);
  border: 1px solid var(--color-gray-4);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 175%;
}

#self-promo a {
  display: inline-flex;
  margin: 0;
  padding: 0px 5px;
  font: inherit;
  text-decoration: underline;
  color: var(--color-blue-1);
  cursor: pointer;
}

#self-promo a:hover {
  opacity: 0.8;
}

#self-promo a.cta-info {
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-blue-1);
  border: 1px solid var(--color-blue-1);
  border-radius: var(--radius-sm);
}

#self-promo a.cta-danger {
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-red-1);
  border: 1px solid var(--color-red-1);
  border-radius: var(--radius-sm);
}

@media screen and (max-width: 560px) {
  #self-promo {
    padding: calc(1rem - 4px) 3rem calc(1rem - 4px) 1rem;
  }
}
