* {
  --bg-odd: var(--color-white);
  --bg-odd-hover: var(--color-gray-1);
  --bg-even: var(--color-blue-7);
  --bg-even-hover: var(--color-gray-1);
  --gradient-odd: linear-gradient(90deg, transparent, var(--bg-odd));
  --h-gradient-odd: linear-gradient(90deg, transparent, var(--bg-odd-hover));
  --gradient-even: linear-gradient(90deg, transparent, var(--bg-even));
  --h-gradient-even: linear-gradient(90deg, transparent, var(--bg-even-hover));
}

.mobile {
  display: none;
}

.table_row__container {
  position: relative;
}

.table_row__container a {
  display: grid;
  grid-template-columns: 80px 1fr 10fr 12% 16% 14% 10%;
  grid-gap: 1px 5px;
  align-items: center;
  padding: 5px 0.65rem;
  text-decoration: none;
  background: var(--bg-odd);
}
li:nth-child(even).table_row__container a {
  background: var(--bg-even);
}
.table_row__container a:hover {
  background-color: var(--bg-odd-hover);
  cursor: pointer;
}
li:nth-child(even).table_row__container a:hover {
  background: var(--bg-even-hover);
}

li.table_row__container {
  border-bottom: 1px solid var(--color-gray-4);
}

.item {
  overflow: visible;
  white-space: nowrap;
  text-align: left;
}

.i_country {
  grid-column: 2;
  grid-row: 1 / span 3;
  font-size: 1.5rem;
  line-height: 180%;
  color: var(--color-blue-3);
  width: fit-content;
  border-right: 1px solid var(--color-gray-4);
  padding-right: 5px;
  white-space: nowrap;
}
.i_region {
  grid-column: 3;
  grid-row: 1;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-black);
  overflow: hidden;
  transform: translateY(7px);
}
.i_hotel {
  grid-column: 3;
  grid-row: 3;
  font-size: 0.9rem;
  color: var(--color-gray-2);
  overflow: hidden;
  transform: translateY(-9px);
}
.i_image {
  width: 80px;
  height: 52px;
  grid-column: 1;
  grid-row: 1 / span 3;
}
.i_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid var(--color-gray-4);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-sm);
}
.i_stars {
  grid-column: 4;
  grid-row: 1 / span 3;
  transform: translateY(3px);
}
.i_stars::before {
  content: '';
  color: transparent;
  background: var(--gradient-odd);
  width: 30px;
  height: 54px;
  display: block;
  transform: translate(-100%, -22px);
  position: absolute;
}
li:nth-child(even) .i_stars::before {
  background: var(--gradient-even);
}
/* SAFARI FIX */
@supports (-webkit-hyphens: none) {
  .i_stars::before {
    display: none;
  }
}

.i_date {
  grid-column: 5;
  grid-row: 1;
  transform: translateY(1px);
  transform: translateY(7px);
}
.i_date span {
  padding: 1px 4px;
  font-size: 0.9rem;
  color: var(--color-blue-1);
  background-color: var(--color-white);
  border: 1px solid var(--color-blue-1);
  border-radius: 6px;
}
.i_boarding {
  grid-column: 5;
  grid-row: 3;
  font-size: 1rem;
  color: var(--color-gray-2);
  padding-left: 3px;
  transform: translateY(-2px);
  transform: translateY(-7px);
}
.i_source {
  grid-column: 6;
  grid-row: 1 / span 3;
  font-size: 1rem;
  color: var(--color-gray-3);
  text-align: center;
}
.i_source span {
  padding: 1px 6px 1px 3px;
  color: var(--color-blue-1);
  background: var(--color-blue-5);
  border-radius: 7px;
}
.i_price {
  grid-column: 7;
  grid-row: 1;
  font-size: var(--price-height);
  color: var(--color-orange-1);
  text-align: right;
  justify-self: end;
}
.i_price_center {
  transform: translateY(14px);
}
.i_price_cut {
  grid-column: 7;
  grid-row: 2;
  font-size: 1rem;
  color: var(--color-green-1);
  text-align: right;
  justify-self: end;
}
.i_min_price {
  grid-column: 7;
  grid-row: 3;
  font-size: 1rem;
  color: var(--color-gray-2);
  text-align: right;
  justify-self: end;
}

.table_row__container a:hover .i_stars::before,
.table_row__container a:hover .i_image::before {
  background: var(--h-gradient-odd);
}
li:nth-child(even).table_row__container a:hover .i_stars::before,
li:nth-child(even).table_row__container a:hover .i_image::before {
  background: var(--h-gradient-even);
}

.table_row__dab_wrapper {
  display: none;
}

.table_row__container a:hover + .table_row__dab_wrapper {
  display: block;
}

@media screen and (max-width: 1000px) {
  .table_row__container a {
    grid-template-columns: 80px 1fr 10fr 10% 16% 11% 12%;
  }
}

@media screen and (max-width: 900px) {
  .table_row__container a {
    grid-template-columns: 62px 1fr 10fr 10% 16% 13% 12%;
  }
  .i_image {
    width: 62px;
  }
}

@media screen and (max-width: 800px) {
  .table_row__container a {
    grid-template-columns: 72px auto 20% 20%;
  }

  .i_image {
    grid-row: 1 / span 4;
    height: 70px;
    width: 70px;
  }
  .i_country {
    grid-row: 1;
    line-height: 110%;
    border-right: none;
  }
  .i_region {
    grid-column: 2;
    grid-row: 2;
    transform: translateY(-3px);
  }
  .i_hotel {
    grid-column: 2;
    transform: none;
  }
  .i_stars {
    grid-column: 2;
    grid-row: 4;
    align-self: end;
    transform: none;
  }
  .i_date {
    grid-column: 3;
    transform: translateY(1px);
  }
  .i_boarding {
    grid-column: 3;
    grid-row: 2 / span 2;
    transform: translateY(-3px);
  }
  .i_source {
    grid-column: 3;
    grid-row: 4;
    transform: translateY(-3px);
  }
  .i_price {
    grid-column: 4;
    transform: translateY(1px);
  }
  .i_price_center {
    transform: translateY(24px);
  }
  .i_price_cut {
    grid-column: 4;
    grid-row: 2 / span 2;
    transform: translateY(-1px);
  }
  .i_min_price {
    grid-column: 4;
    grid-row: 4;
    transform: translateY(-2px);
  }

  .i_stars::before {
    content: none;
  }
  .i_date::before {
    content: 'x';
    color: transparent;
    background: var(--gradient-odd);
    width: 30px;
    height: 76px;
    display: block;
    transform: translate(-100%, -6px);
    position: absolute;
  }
  li:nth-child(even) .i_date::before {
    background: var(--gradient-even);
  }
  .table_row__container a:hover .i_date::before {
    background: var(--h-gradient-odd);
  }
  li:nth-child(even).table_row__container a:hover .i_date::before {
    background: var(--h-gradient-even);
  }
  /* SAFARI FIX */
  @supports (-webkit-hyphens: none) {
    .i_date::before,
    .table_row__container a:hover .i_date::before {
      display: none;
    }
  }
}

@media screen and (max-width: 560px) {
  .table_row__container a {
    grid-template-columns: 72px auto 7rem 7rem;
  }
}

@media screen and (max-width: 450px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .table_row__container a {
    grid-template-columns: 56px auto 5.5rem 5.2rem;
  }
  .i_image {
    width: 56px;
  }
  .i_country {
    overflow: hidden;
  }
  .i_region {
    transform: translateY(-2px);
  }
  .i_boarding {
    transform: translateY(-2px);
  }
  .i_source span {
    padding: 1px 6px 1px 6px;
  }
  .i_min_price {
    transform: translateY(-3px);
  }
}
