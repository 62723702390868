.destination_options {
  min-height: 100px;
  margin: 4px 0 1rem;
}

.destination_item {
  display: flex;
  flex-direction: column;
}

.destination_item__toggle {
  cursor: pointer;
}

.destination_item__toggle svg {
  display: inline;
  width: 1rem;
  height: auto;
  transition: transform 0.2s linear;
  cursor: pointer;
}

.destination_item__asterisk {
  font-size: 1rem;
  padding-right: 2px;
  color: var(--color-blue-1);
}

.destination_hr {
  height: 6px;
  margin: 0 0 10px;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-4);
}

.destination_options p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--color-blue-1);
  margin: 0 3px 0 5px;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .destination_hr {
    height: 6px;
    margin: 0 0 16px;
    width: 100%;
    border-bottom: 1px solid var(--color-gray-4);
  }
  .destination_options p {
    padding-bottom: 1rem;
    font-size: 1.25rem;
  }
}
