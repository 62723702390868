.search_trip__trash_icon {
  position: absolute;
  top: 16px;
  right: 14px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.search_trip__share_icon {
  position: absolute;
  top: 17px;
  right: 48px;
  width: 24px;
  height: 22px;
  cursor: pointer;
}

.search_trip__dropdown_icon_container {
  display: none;
  position: absolute;
  width: 100%;
  height: 3.5rem;
  top: 8px;
  right: 0;
}

.search_trip__dropdown_icon {
  position: absolute;
  top: 7px;
  right: 16px;
  width: 24px;
  height: 24px;
  transition: transform 0.2s linear;
  cursor: pointer;
}

.stt_icon_color {
  fill: var(--color-gray-6);
}

.pulse {
  animation: pulse 1s linear infinite;
}

@media screen and (max-width: 560px) {
  .search_trip__dropdown_icon_container {
    display: block;
  }
  .search_trip__trash_icon {
    right: 50px;
  }
  .search_trip__share_icon {
    right: 84px;
  }
}

@media screen and (max-width: 410px) {
  .search_trip__dropdown_icon {
    display: none;
  }
  .stdi__visible_on_mobile {
    display: block !important;
  }
  .search_trip__trash_icon {
    right: 14px;
  }
  .search_trip__share_icon {
    right: 48px;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
