#cookie_info {
  position: fixed;
  top: auto;
  bottom: 0;
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 1rem;
  text-align: justify;
  color: var(--color-gray-3);
  background: var(--color-blue-5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 90;
  opacity: 0;
  animation: show 0.3s ease forwards;
  animation-delay: 0.5s;
}

.cookie_info__text {
  position: relative;
}

.cookie_info__text p {
  padding: 0;
  margin: 0;
  line-height: 1.5rem;
  overflow-y: hidden;
}

.cit_collapsed {
  max-height: none;
}

.cookie_info__text a {
  color: inherit;
  text-decoration: underline;
}

.cookie_info__show_more {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--color-blue-5);
  padding: 0;
  font-weight: 500;
  line-height: 1.5rem;
  border: none;
}

.cookie_info__show_more::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: 60px;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, var(--color-blue-5) 90%);
}
/* SAFARI FIX */
@supports (-webkit-hyphens: none) {
  .cookie_info__show_more::before {
    background: var(--color-blue-5);
    width: 4px;
  }
}

.cookie_info__buttons {
  text-align: center;
  margin-top: 5px;
}

@media screen and (max-width: 560px) {
  .cit_collapsed {
    max-height: 4.5rem;
  }

  .cookie_info__show_more {
    display: block;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
