* {
  --color-background: #f2ebda;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-1: #f7f9fd;
  --color-white-2: #d8e1f2;
  --color-red-1: #d93d2f;
  --color-red-2: #b42d21;
  --color-red-3: #f85f51;
  --color-orange-1: #f94a05;
  --color-gray-1: #e9edf5;
  --color-gray-2: #7c7c7c;
  --color-gray-3: #383837;
  --color-gray-4: #dedede;
  --color-gray-5: #f8f8f8;
  --color-gray-6: #c4c4c4;
  --color-blue-1: #4f55a6;
  --color-blue-2: #bfceec;
  --color-blue-3: #4488b7;
  --color-blue-4: #e9f6fe;
  --color-blue-5: #aee0ff;
  --color-blue-6: rgba(109, 74, 255, 0.5);
  --color-blue-7: #f8fcff;
  --color-green-1: #008400;
  --color-green-2: #4caf50;
  --color-green-3: #57a71d;
  --color-green-4: #49b22e;
  --color-green-5: #090;
  --color-green-6: #b2f4b2;
  --color-green-7: #ccf4cc;
  --color-yellow-1: #f9edbe;
  --scrollbar-foreground: var(--color-blue-3);
  --scrollbar-background: transparent;

  --dropdown-width: 205px;
  --dropdown-max-height: 60vh;
  --select-component-width: 24%;
  --datepicker-item: 1.75rem;
  --price-height: 1.5rem;
  --fluid-margin: calc(50% - 450px);
  --modal-box-dropdown-width: 410px;
  --modal-box-centered-width: 540px;
  --header-input-width: 285px;
  --radius-lg: 11px;
  --radius-sm: 6px;

  box-sizing: border-box;
  font-size: 12px;
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}

ul,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  text-align: center;
  background-color: var(--color-background);
  background-image: url('assets/images/bg1920.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
}

.main_container {
  position: relative;
  padding-top: 70px;
}

.fluid_container {
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
}

.text_input__field {
  padding: 0;
  margin: 0 0 0.5rem 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
}

.text_input__label {
  color: var(--color-blue-1);
  margin: 0px;
}

.text_input__input {
  font: inherit;
  width: 5rem;
  text-align: right;
  margin: 0 0.25rem 0 0.375rem;
  padding: 0.25rem;
  color: var(--color-blue-1);
  background: var(--color-white);
  background: var(--color-blue-4);
  border: 1px solid var(--color-gray-1);
  border-radius: 2px;
  outline: none;
}

.text_input__input:focus {
  background: var(--color-blue-5);
  border: 1px solid var(--color-blue-2);
}

.icon_hover_effect {
  transition: transform 0.2s ease-in-out;
}

.icon_hover_effect:hover {
  transform: scale(1.1);
}

.custom_scrollbar {
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
.custom_scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.custom_scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-foreground);
}
.custom_scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}

@media screen and (max-width: 1920px) {
  body {
    background-size: auto;
  }
}

@media screen and (max-width: 1360px) {
  body {
    background-image: url('assets/images/bg1360.png');
  }
}

/* TABLET */
@media screen and (max-width: 1140px) {
  * {
    --fluid-margin: 10%;
    --datepicker-item: 1.95rem;
    --dropdown-width: 240px;
    --select-component-width: 49%;
  }
  .fluid_container {
    min-width: 520px;
  }
}

@media screen and (max-width: 1024px) {
  body {
    background-image: url('assets/images/bg1024.png');
  }
}

@media screen and (max-width: 800px) {
  * {
    --price-height: 1.75rem;
  }
}


@media screen and (max-width: 650px) {
  * {
    --fluid-margin: calc(50% - 260px);
  }
}

/* MOBILE */
@media screen and (max-width: 560px) {
  * {
    --dropdown-width: 230px;
    --dropdown-max-height: 80vh;
    --select-component-width: 100%;
    --datepicker-item: 2.48rem;
    --fluid-margin: 0;
    --modal-box-dropdown-width: 100%;
    --modal-box-centered-width: calc(100% - 20px);
  }
  .fluid_container {
    width: 100%;
    min-width: 360px;
  }
}

@media screen and (max-width: 450px) {
  * {
    --price-height: 1.5rem;
  }
}
