.badge {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  margin: 1px 2px 1px 2px;
  font-size: 1rem;
  color: var(--color-blue-1);
  background: var(--color-blue-5);
  border-radius: 0.625rem;
  white-space: nowrap;
}

.badge_inactive {
  color: var(--color-gray-2);
  background: var(--color-gray-5);
  border: 1px solid var(--color-gray-6);
}

.badge__icon {
  flex-grow: 0;
  margin: 0 0.25rem 0 0;
  width: 1rem;
  height: 1rem;
}

.badge__icon_color {
  stroke: var(--color-blue-1);
}
