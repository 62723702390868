.box {
  width: 100%;
  padding: 1.2rem 1.25rem 1.25rem 1.25rem;
  margin: 0 auto 10px;
  background-color: var(--color-white);
  text-align: left;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-lg);
}

@media screen and (max-width: 560px) {
  .box {
    width: calc(100% - 20px);
    padding: 1rem;
  }
}
