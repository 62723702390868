.dest_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 55px);
  height: 100%;
  padding: 0.25rem;
  margin: 0 0 1.5rem;
  color: var(--color-blue-1);
  background: var(--color-blue-4);
  border: 1px solid var(--color-gray-1);
  border-radius: 2px;
  outline: none;
}

.dest_input__input {
  display: block;
  flex-grow: 1;
  font-size: 1rem;
  max-width: calc(100% - 1.25rem);
  color: var(--color-blue-1);
  background: transparent;
  border: none;
  outline: none;
}

.dest_input__input::placeholder {
  color: var(--color-gray-2);
  opacity: 0.6;
}

.dest_input__focus {
  border-color: var(--color-blue-2);
}

.dest_input__input_empty {
  max-width: 100%;
}

.dest_input button {
  position: relative;
  background: transparent;
  border: none;
  width: 16px;
  height: 100%;
  cursor: pointer;
}

.dest_input button::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 0;
  width: 12px;
  height: 100%;
  background: linear-gradient(90deg, transparent, var(--color-blue-4));
}

.dest_input__close_icon {
  width: auto;
  height: 12px;
  margin-bottom: -2px;
  margin-left: -4px;
  cursor: pointer;
}

.dest_input__close_icon_svg {
  stroke: var(--color-gray-6);
}

@media screen and (max-width: 560px) {
  .dest_input__input {
    font-size: 1.25rem;
  }
  .dest_input__close_icon {
    height: 14px;
    margin-left: -6px;
  }
}
