.checkbox__field {
  display: flex;
  align-items: center;
  padding: 0 0 0.375rem 0;
  width: fit-content;
  align-self: start;
}

.checkbox__field input {
  opacity: 0;
  position: absolute;
  left: 3rem;
  margin: 0;
  padding: 0;
  z-index: -1;
}

.checkbox__checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  background: var(--color-blue-4);
  border: 1px solid var(--color-white-2);
  border-radius: 2px;
  margin: 0;
  cursor: pointer;
  flex-shrink: 0;
}

.checkbox__field input:focus + .checkbox__checkbox {
  background: var(--color-blue-5);
  border: 1px solid var(--color-blue-2);
}

.chbx_disabled {
  cursor: default;
}

.chbx_checked {
  background: var(--color-blue-5);
  border: 1px solid var(--color-blue-1);
}

.chbx_semichecked {
  background: transparent;
}

.checkbox__checkbox img {
  width: 0.75rem;
  height: 0.75rem;
}

.checkbox__field label {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--color-blue-1);
  margin: 0 3px 0 5px;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .checkbox__field {
    padding-bottom: 1rem;
  }
  .checkbox__field label {
    font-size: 1.25rem;
  }
  .checkbox__checkbox {
    width: 1.5rem;
    height: 1.5rem;
  }
}
