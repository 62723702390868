.dont_miss {
  padding: 5px 9px 5px;
  background: var(--color-yellow-1);
  border: 1px solid var(--color-gray-4);
  border-radius: var(--radius-sm);
}

.dont_miss__alert_icon {
  height: 20px;
  width: auto;
  margin: 0 4px 0 1px;
  transform: translateY(3px);
}

.dont_miss p {
  margin: 0 0 2px;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 125%;
}

.dont_miss__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dont_miss__buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 14px;
  margin: 5px 14px 0 0;
}

.dont_miss__buttons span {
  display: block;
  margin: 0 2px 4px;
  padding: 3px 5px;
  height: 22px;
  font-size: 1rem;
  color: var(--color-red-1);
  border: 1px solid var(--color-red-1);
  border-radius: var(--radius-sm);
  cursor: pointer;
}

.dont_miss__buttons span:hover,
.dont_miss__buttons span.selected {
  color: var(--color-white);
  background-color: var(--color-red-1);
}
.dont_miss__buttons span.selected::before {
  content: url('/src/assets/images/checked-white.svg');
  margin-right: 2px;
}

.dont_miss__plus_icon {
  margin-bottom: 4px;
}

.dont_miss__smart_button {
  display: flex;
  align-items: center;
  height: 22px;
  margin: 5px 2px 4px;
}

.dont_miss__button {
  padding: 0 0.5rem;
  margin: 0;
  height: 22px;
  min-width: 60px;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: var(--color-white);
  background: var(--color-blue-1);
  border-radius: 0.625rem;
  border: 1px solid var(--color-blue-1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: opacity 300ms;
  z-index: 2;
}
.dont_miss__button:hover {
  opacity: 0.8;
}

.dont_miss__info {
  margin: 2px 0 0 5px;
  color: var(--color-red-1);
  font-size: 9px;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
  transform: translateX(-120%);
  transition: transform 0.1s ease;
  opacity: 0;
  z-index: 1;
}

.npi_visible {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.1s ease;
}

@media screen and (max-width: 450px) {
  .dont_miss {
    padding-top: 4px;
  }
  .dont_miss p {
    font-size: 1.25rem;
  }
  .dont_miss__alert_icon {
    transform: translateY(4px);
  }
}
