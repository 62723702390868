.options_selected__container {
  height: 3.75rem;
  margin-top: 3px;
  font-size: 1.2rem;
  background: var(--color-blue-4);
  border: 1px solid var(--color-gray-1);
  border-radius: var(--radius-sm);
}

.options_selected__field {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 5px;
  padding-right: 34px;
  font-size: inherit;
  overflow: hidden;
  cursor: pointer;
}

.options_selected__placeholder {
  font-weight: 400;
  font-size: inherit;
  color: var(--color-gray-2);
  opacity: 0.6;
  flex-shrink: 0;
  padding-left: 2px;
}

.options_selected__field ul {
  display: flex;
  width: 100%;
}
