.modal_dropdown {
  position: fixed;
  top: 60px;
  right: var(--fluid-margin);
  width: var(--modal-box-dropdown-width);
  max-height: calc(100vh - 80px);
  min-height: 40px;
  overflow: auto;
  padding: 0.75rem;
  text-align: left;
  background: var(--color-white);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px var(--radius-lg) var(--radius-lg);
}

.md_rigth_offset {
  right: calc(var(--fluid-margin) + 80px);
}

.modal_centered_box {
  position: absolute;
  left: 50%;
  top: calc(50% + 30px);
  transform: translate(-50%, -50%);
  width: var(--modal-box-centered-width);
  max-height: calc(100vh - 60px - 0px);
  padding-top: 25px;
  overflow-y: auto;
}
