.close_button {
  right: 12px;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.close_button__icon {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}

.close_button__icon_color_default {
  stroke: var(--color-gray-6);
}

.close_button__icon_color_white {
  stroke: var(--color-white);
}

.cb_in_corner {
  position: absolute;
  top: 12px;
}

.cb_above {
  position: absolute;
  top: 5px;
}

.cb_dest_options_dropdown {
  top: 14px !important;
  right: 12px !important;
}

@media screen and (max-width: 560px) {
  .close_button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .cb_dest_options_dropdown {
    top: 16px !important;
    right: 12px !important;
  }
}
