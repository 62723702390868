.date_options__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -5px;
}

.date_options__item {
  display: block;
  margin: 0 auto;
}

.date_options__input_container {
  width: calc(100% - 2rem);
}

.date_options__input_label {
  color: var(--color-blue-1);
  margin: 0 0.375rem 2px 0;
}

.date_options__input {
  font: inherit;
  font-size: 1rem;
  width: 8rem;
  text-align: right;
  margin: 0;
  padding: 0.25rem;
  color: var(--color-blue-1);
  background: var(--color-blue-4);
  border: 1px solid var(--color-gray-1);
  border-radius: 2px;
  outline: none;
}

.date_options__remove_icon {
  margin: 0;
  width: 0.75rem;
  height: 0.75rem;
  transform: translateX(-7.5rem);
  cursor: pointer;
}

.calendar {
  font: inherit;
  background: var(--color-white);
  border: 1px solid var(--color-blue-2);
  border-radius: var(--radius-sm);
  margin: 5px 0 10px 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background: var(--color-blue-5);
  border-top-right-radius: 2px !important;
  border-top-left-radius: 2px !important;
  border-bottom: none;
}

.react-datepicker__current-month {
  color: var(--color-blue-1);
  font-size: 1.1rem;
}

.react-datepicker__navigation {
  width: 2rem;
}

.react-datepicker__navigation:hover ::before {
  border-color: var(--color-blue-1);
  margin-top: -1px;
}

.react-datepicker__navigation::before {
  border-color: var(--color-blue-1);
  width: 0.5rem;
  height: 0.5rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--color-blue-1);
  width: var(--datepicker-item);
  line-height: var(--datepicker-item);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: var(--color-blue-5);
}

.react-datepicker__day--selected {
  border: 1px solid var(--color-blue-1);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: var(--color-blue-2);
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #999;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  color: #fff;
}

@media screen and (max-width: 560px) {
  .date_options__input_label {
    font-size: 1.25rem;
  }
  .date_options__input {
    font-size: 1.25rem;
    width: 9.5rem;
  }
  .date_options__remove_icon {
    width: 0.85rem;
    height: 0.85rem;
    transform: translateX(-9rem);
  }
}
