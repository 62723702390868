.share_buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.share_btn {
  margin: 5px;
  transition: transform 0.2s ease-in-out;
}

.share_btn:hover {
  transform: scale(1.1);
}
