.dropdown {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  width: var(--dropdown-width);
  overflow: hidden;
  background: var(--color-white);
  border: 1px solid var(--color-gray-1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-sm);
  padding: 0.75rem 7px 0;
  z-index: 20;
}

.dropdown_fixed {
  position: fixed;
  right: calc(
    var(--fluid-margin) +
      (var(--modal-box-dropdown-width) - var(--dropdown-width)) / 2
  );
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
}

.dropdown ul,
.dropdown li {
  width: fit-content;
}

@media screen and (max-width: 560px) {
  .dropdown {
    padding: 1rem 10px 0;
  }
  .dropdown_fixed {
    right: calc((var(--modal-box-dropdown-width) - var(--dropdown-width)) / 2);
  }
}
