.options_group__container {
  margin: 4px 0 1rem 0;
  padding: 0;
}

.options_group__name {
  font-weight: 600;
  font-size: 1rem;
  line-height: 10px;
  color: var(--color-blue-1);
  margin: 0 0 0.625rem 0;
}

.options_group__subgroup {
  margin-left: 1.25rem;
}

@media screen and (max-width: 560px) {
  .options_group__name {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}
