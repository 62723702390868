.trips_table__container {
  position: relative;
  overflow: hidden;
}

.trips_table__modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 240, 240, 0.8);
  z-index: 1;
}

.blur {
  filter: blur(2px);
}
